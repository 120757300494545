@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@keyframes shine {
  0% {
    box-shadow: 0 0 0.1rem #fff; }
  50% {
    box-shadow: 0 0 3rem #fff; }
  100% {
    box-shadow: 0 0 0.1rem #fff; } }

@keyframes tail {
  0% {
    width: 0; }
  30% {
    width: 100px; }
  100% {
    width: 0; } }

@keyframes shining {
  0% {
    width: 0; }
  50% {
    width: 30px; }
  100% {
    width: 0; } }

@keyframes shooting {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(320px); } }

@keyframes gradientBG {
  0% {
    background-position: 0% 0%; }
  50% {
    background-position: 100% 100%; }
  100% {
    background-position: 0% 0%; } }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(15rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-15rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@font-face {
  font-family: "archiaregular";
  src: url("../fonts/archia-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "modernplate";
  src: url("../fonts/ModernPlate.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; }
  *:focus, *:active {
    outline: none !important; }

html {
  font-size: 62.5%;
  background-color: #000353;
  scroll-behavior: smooth;
  overflow-x: hidden !important; }
  @media only screen and (max-width: 100em) {
    html {
      font-size: 60%; } }
  @media only screen and (max-width: 90em) {
    html {
      font-size: 56.25%;
      overflow-y: visible !important; } }
  @media only screen and (max-width: 65em) {
    html {
      font-size: 50%; } }
  @media only screen and (max-width: 44em) {
    html {
      font-size: 40%; } }

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  font-size: 3rem; }

a:link,
a:visited {
  color: #0d65f1; }

a:focus,
a:active {
  outline: none; }

::-moz-selection {
  background-color: #fff;
  color: #141852; }

::selection {
  background-color: #fff;
  color: #141852; }

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #000; }

body::-webkit-scrollbar {
  width: 7px;
  background-color: #000; }

body::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ccc; }

.MuiTooltip-tooltip {
  font-size: 1.4rem !important; }

.landing {
  height: auto;
  min-height: 100vh;
  width: 100vw;
  padding-top: 5%;
  background-image: linear-gradient(135deg, #03003b, #00102e);
  background-size: 300% 300%;
  animation: gradientBG 8s cubic-bezier(0.7, 0, 0.2, 1) infinite; }
  @media only screen and (max-width: 90em) {
    .landing {
      background-size: 200% 200%; } }
  .landing-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 3rem 10%;
    z-index: 500;
    background-image: linear-gradient(15deg, #467cd3, #001838);
    background-size: 300% 300%;
    -webkit-animation: gradientBG 5s cubic-bezier(0.7, 0, 0.2, 1) infinite;
    animation: gradientBG 5s cubic-bezier(0.7, 0, 0.2, 1) infinite; }
    @media only screen and (max-width: 44em) {
      .landing-top {
        padding: 3rem 10% 2rem; } }
    .landing-top-logo, .footer-logo {
      font-family: "modernplate";
      font-size: 4rem;
      -webkit-animation: moveInTop 0.5s ease 0.75s;
      animation: moveInTop 0.5s ease 0.75s;
      -webkit-animation-fill-mode: backwards;
      animation-fill-mode: backwards; }
      .landing-top-logo, .footer-logo, .landing-top-logo:link, .footer-logo:link, .landing-top-logo:visited, .footer-logo:visited {
        text-decoration: none;
        color: #f7f7f2; }
      .landing-top-logo:hover, .footer-logo:hover {
        cursor: pointer;
        animation: bounce 0.5s alternatea cubic-bezier(0.95, 0.05, 0.795, 0.035) infinite; }
    .landing-top-nav {
      display: flex;
      align-items: center;
      font-size: 2.6rem;
      -webkit-animation: moveInTop 1s ease 0.75s;
      animation: moveInTop 1s ease 0.75s;
      -webkit-animation-fill-mode: backwards;
      animation-fill-mode: backwards; }
      .landing-top-nav-link {
        margin-left: 4rem; }
        .landing-top-nav-link::after {
          content: "";
          position: relative;
          display: block;
          height: 3px;
          width: 100%;
          background-color: #fff;
          transform: scaleX(0);
          transform-origin: 0%;
          transition: transform 300ms ease;
          top: 2px; }
        .landing-top-nav-link:hover::after {
          transform: scaleX(1); }
        .landing-top-nav-link:hover {
          cursor: pointer; }
        .landing-top-nav-link, .landing-top-nav-link:link, .landing-top-nav-link:visited {
          text-decoration: none;
          color: #f7f7f2; }
        .landing-top-nav-link_work {
          margin-left: 15rem; }
          @media only screen and (max-width: 44em) {
            .landing-top-nav-link_work {
              margin-left: 0; } }
        .landing-top-nav-link_selected, .landing-top-nav-link_selected:link, .landing-top-nav-link_selected:visited {
          margin-left: 4rem;
          text-decoration: none;
          color: #f7f7f2; }
          .landing-top-nav-link_selected::after, .landing-top-nav-link_selected:link::after, .landing-top-nav-link_selected:visited::after {
            content: "";
            position: relative;
            display: block;
            height: 3px;
            width: 100%;
            background-color: #fff;
            transform: scaleX(1);
            transform-origin: 0%;
            top: 2px; }
  .landing-heading {
    font-family: "Roboto";
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 85vh;
    width: 60%;
    padding: 0 10%;
    line-height: 1.6; }
    @media only screen and (max-width: 90em) {
      .landing-heading {
        width: 100%; } }
    .landing-heading-top-title {
      font-size: 5rem;
      animation: moveInRight 1s ease 0.75s;
      animation-fill-mode: backwards;
      color: #f7f7f2; }
      @media only screen and (max-width: 90em) {
        .landing-heading-top-title {
          font-size: 4rem; } }
    .landing-heading-top-subtitle {
      margin-top: 3rem;
      font-size: 3.2rem;
      font-weight: 300;
      width: 85%;
      color: #f7f7f2;
      animation: moveInLeft 1s ease 1s;
      animation-fill-mode: backwards; }
      @media only screen and (max-width: 44em) {
        .landing-heading-top-subtitle {
          font-size: 2.8rem; } }
    .landing-heading-button {
      -webkit-animation: moveInTop 1s ease 0.75s;
      animation: moveInTop 1s ease 0.75s;
      -webkit-animation-fill-mode: backwards;
      animation-fill-mode: backwards; }
      .landing-heading-button, .landing-heading-button:link, .landing-heading-button:visited {
        text-decoration: none;
        color: #fff;
        margin-top: 10%;
        padding: 1.5rem 2rem;
        font-weight: 500;
        text-transform: uppercase;
        background-color: #0d65f1;
        transition: all 0.3s ease; }
        .landing-heading-button:hover, .landing-heading-button:link:hover, .landing-heading-button:visited:hover {
          background-color: #1565c0; }
        @media only screen and (max-width: 44em) {
          .landing-heading-button, .landing-heading-button:link, .landing-heading-button:visited {
            margin: 20% 0 0; } }

.star {
  position: absolute; }
  .star-1 {
    top: 60%;
    left: 75%;
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 50%;
    background-color: #007575;
    animation: shine 5s ease 3s infinite, fadeIn 1s ease 1.15s backwards; }
  .star-2 {
    top: 74%;
    left: 84%;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #5aa2ff;
    animation: shine 5s ease 1s infinite, fadeIn 1s ease 1s backwards; }
  .star-3 {
    top: 50%;
    left: 87%;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background-color: #ff6464;
    animation: shine 5s ease 2s infinite, fadeIn 1s ease 1.1s backwards; }
  .star-4 {
    top: 34%;
    left: 70%;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background-color: #fdbaf6;
    animation: shine 5s ease 5s infinite, fadeIn 1s ease 1s backwards; }
  .star-5 {
    top: 50%;
    left: 80%;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #fff780;
    animation: shine 5s ease 5s infinite, fadeIn 1s ease 1s backwards; }

.shooting-star-container {
  position: absolute;
  top: 60%;
  left: 75%;
  transform: rotateZ(45deg); }
  @media only screen and (max-width: 44em) {
    .shooting-star-container {
      left: 10%;
      top: 10%; } }

.shooting-star {
  position: absolute;
  top: 60%;
  left: 60%;
  height: 2px;
  background: linear-gradient(-45deg, #fff8d3, rgba(0, 0, 255, 0));
  filter: drop-shadow(0 0 6px #fff8d3);
  animation: tail 6000ms ease-in-out 5s infinite, shooting 6000ms ease-in-out 5s infinite; }

.shooting-star::before,
.shooting-star::after {
  position: absolute;
  content: "";
  top: calc(50% - 1px);
  right: 0;
  height: 2px;
  background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #fff8d3, rgba(0, 0, 255, 0));
  border-radius: 100%;
  transform: translateX(50%) rotateZ(45deg);
  animation: shining 6000ms ease-in-out 5s infinite; }

.shooting-star::after {
  transform: translateX(50%) rotateZ(-45deg); }

.shooting-star:nth-child(1) {
  top: calc(50% - 200px);
  left: calc(50% - 300px);
  animation-delay: 650ms; }

.shooting-star:nth-child(1)::before,
.shooting-star:nth-child(1)::after {
  animation-delay: 650ms; }

.shooting-star:nth-child(2) {
  top: calc(50% - -50px);
  left: calc(50% - 190px);
  animation-delay: 150ms; }

.shooting-star:nth-child(2)::before,
.shooting-star:nth-child(2)::after {
  animation-delay: 150ms; }

.shooting-star:nth-child(3) {
  top: calc(50% - -90px);
  left: calc(50% - 200px);
  animation-delay: 1600ms; }

.shooting-star:nth-child(3)::before,
.shooting-star:nth-child(3)::after {
  animation-delay: 1600ms; }

.shooting-star:nth-child(4) {
  top: calc(50% - 50px);
  left: calc(50% - 250px);
  animation-delay: 4700ms; }

.shooting-star:nth-child(4)::before,
.shooting-star:nth-child(4)::after {
  animation-delay: 4700ms; }

.section-one {
  height: 50vh;
  background-color: #04061a; }

.scroll-down {
  position: absolute;
  left: 50%;
  bottom: 5%;
  display: block;
  text-align: center;
  font-size: 20px;
  z-index: 100;
  text-decoration: none;
  text-shadow: 0;
  width: 13px;
  height: 13px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  z-index: 200;
  -webkit-transform: translate(-50%, 0%) rotate(45deg);
  -moz-transform: translate(-50%, 0%) rotate(45deg);
  transform: translate(-50%, 0%) rotate(45deg);
  -webkit-animation: fade_move_down 4s ease-in-out infinite;
  -moz-animation: fade_move_down 4s ease-in-out infinite;
  animation: fade_move_down 4s ease-in-out infinite; }
  @media only screen and (max-width: 65em) {
    .scroll-down {
      bottom: 15%;
      left: 48%; } }

/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_down {
  0% {
    -webkit-transform: translate(0, -10px) rotate(45deg);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: translate(0, 10px) rotate(45deg);
    opacity: 0; } }

@-moz-keyframes fade_move_down {
  0% {
    -moz-transform: translate(0, -10px) rotate(45deg);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -moz-transform: translate(0, 10px) rotate(45deg);
    opacity: 0; } }

@keyframes fade_move_down {
  0% {
    transform: translate(0, -10px) rotate(45deg);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    transform: translate(0, 10px) rotate(45deg);
    opacity: 0; } }

.social-links {
  position: fixed;
  bottom: 0;
  background-image: linear-gradient(15deg, #467cd3, #001838);
  background-size: 300% 300%;
  -webkit-animation: gradientBG 5s cubic-bezier(0.7, 0, 0.2, 1) infinite;
  animation: gradientBG 5s cubic-bezier(0.7, 0, 0.2, 1) infinite;
  z-index: 201; }
  .social-links-nav {
    -webkit-animation: moveInTop 0.5s ease 0.75s;
    animation: moveInTop 0.5s ease 0.75s;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards; }

.work {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 10% 10rem;
  color: #f7f7f2;
  animation: fadeIn 0.5s ease-out 0.5s;
  animation-fill-mode: backwards;
  overflow-y: visible !important; }

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10% 5rem;
  color: #fff;
  height: auto;
  background-image: linear-gradient(135deg, #03003b, #00102e);
  background-size: 300% 300%;
  animation: gradientBG 8s cubic-bezier(0.7, 0, 0.2, 1) infinite; }
  @media only screen and (max-width: 90em) {
    .contact {
      background-size: 200% 200%; } }
  .contact-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0 0;
    width: 50%;
    text-align: center; }
    @media only screen and (max-width: 44em) {
      .contact-text {
        margin: 10rem 0 0;
        width: 80%; } }
    .contact-text__title {
      font-size: 4rem; }
    .contact-text__paragraph {
      font-size: 3rem;
      line-height: 1.5;
      margin-top: 3rem; }
  .contact-links {
    width: 60rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: -10rem; }
    @media only screen and (max-width: 44em) {
      .contact-links {
        width: 55rem; } }
    .contact-links__linkedin, .contact-links__linkedin:link, .contact-links__linkedin:visited {
      width: 50px;
      height: 50px;
      background-image: url("../images/logos/linkedinlogo.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 0.1s ease-in-out; }
      @media only screen and (max-width: 44em) {
        .contact-links__linkedin, .contact-links__linkedin:link, .contact-links__linkedin:visited {
          width: 35px;
          height: 35px; } }
    .contact-links__linkedin:hover {
      transform: scale(1.1);
      cursor: pointer; }
    .contact-links__linkedin::after {
      content: "";
      display: table;
      clear: both; }
    .contact-links__resume, .contact-links__resume:link, .contact-links__resume:visited {
      background-color: #ddd;
      color: #111;
      font-size: 2.2rem;
      border: 1px solid #111;
      text-decoration: none;
      border-radius: 3px;
      padding: 1rem 1.5rem;
      text-transform: uppercase;
      margin: 5rem 0;
      align-self: flex-end;
      transition: all 0.2s ease-out; }
    .contact-links__resume:hover {
      background-color: #fff;
      color: #222;
      cursor: pointer; }
    .contact-links__github, .contact-links__github:link, .contact-links__github:visited {
      width: 55px;
      height: 55px;
      background-image: url("../images/logos/githublogo.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 0.1s ease-in-out; }
      @media only screen and (max-width: 44em) {
        .contact-links__github, .contact-links__github:link, .contact-links__github:visited {
          width: 35px;
          height: 35px; } }
    .contact-links__github:hover {
      transform: scale(1.1);
      cursor: pointer; }
    .contact-links__github::after {
      content: "";
      display: table;
      clear: both; }
  .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45rem;
    margin: 7.5rem auto;
    font-size: 2.6rem; }
    @media only screen and (max-width: 44em) {
      .contact-form {
        flex-direction: column;
        margin: 10rem 0;
        padding-bottom: 15rem; } }
    .contact-form-button {
      background-color: #0d65f1;
      color: #fff;
      font-size: 2.2rem;
      font-family: "Roboto";
      border: none;
      padding: 1.5rem 2rem;
      font-weight: 500;
      text-transform: uppercase;
      margin-top: 3rem;
      align-self: center;
      transition: all 0.2s ease-out; }
      .contact-form-button:hover {
        background-color: #1565c0;
        color: #fff;
        cursor: pointer; }

.about {
  animation: fadeIn 0.5s ease-out 0.5s;
  animation-fill-mode: backwards;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  overflow-y: visible; }
  .about-services {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr));
    column-gap: 10rem;
    row-gap: 5rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem; }
    @media only screen and (max-width: 44em) {
      .about-services {
        display: flex;
        flex-direction: column;
        grid-template-columns: none;
        column-gap: 0rem;
        row-gap: 5rem; } }
    .about-services-each {
      width: 100%; }
      @media only screen and (max-width: 44em) {
        .about-services-each {
          text-align: center;
          margin-top: 5rem; } }
      .about-services-each-container-title {
        font-size: 3.2rem;
        font-weight: 800;
        margin-bottom: 2rem; }
  .about-story {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10%;
    text-align: center; }
    .about-story-title {
      margin: 2rem 0 5rem;
      color: "#000 !important"; }
    .about-story-paragraph {
      line-height: 1.5; }
      @media only screen and (max-width: 44em) {
        .about-story-paragraph {
          width: 100%; } }
  .about-skills {
    display: flex;
    flex-direction: column;
    padding-bottom: 10rem; }
    @media only screen and (max-width: 44em) {
      .about-skills {
        padding-bottom: 10rem; } }
    .about-skills-title {
      align-items: center;
      margin: 10rem auto 3rem;
      display: inline; }
    .about-skills-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
      padding: 3rem 0 0 15rem;
      justify-content: center;
      align-items: center;
      width: 90%; }
      @media only screen and (max-width: 44em) {
        .about-skills-list {
          grid-template-columns: repeat(auto-fit, 200px);
          padding: 3rem 0 0 15rem;
          margin: 0 auto;
          overflow-x: hidden; } }
      .about-skills-list-each {
        display: flex;
        align-items: center;
        margin: 2rem auto; }
        @media only screen and (max-width: 44em) {
          .about-skills-list-each {
            font-size: 2.6rem;
            margin: 2rem 0; } }
        .about-skills-list-each-image {
          height: 35px;
          width: 35px;
          display: inline-block;
          margin-right: 1rem; }

.youtube-video {
  height: 100%;
  width: 100%;
  margin: 0rem auto 5rem; }

.footer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  height: auto;
  align-items: center;
  font-size: 2rem;
  color: #f7f7e7;
  padding: 3rem 0 6rem; }
  @media only screen and (max-width: 44em) {
    .footer {
      flex-direction: column; } }
  @media only screen and (max-width: 44em) {
    .footer-logo {
      display: none; } }
  .footer-contact {
    width: 33%;
    text-align: center;
    font-size: 2.8rem; }
    @media only screen and (max-width: 44em) {
      .footer-contact {
        width: 100%; } }
  .footer a {
    color: #0077b5;
    padding: 3rem 0; }
    .footer a:hover {
      cursor: pointer; }
  .footer-links {
    display: flex;
    width: 33%;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 44em) {
      .footer-links {
        width: 100%;
        margin-bottom: 3rem; } }
    .footer-links > * {
      font-size: 4rem;
      transition: all 0.2s ease-out;
      padding: 1rem; }
    .footer-links_resume, .footer-links_resume:link, .footer-links_resume:visited {
      font-size: 3rem; }
    .footer-links_linkedin, .footer-links_linkedin:link, .footer-links_linkedin:visited {
      color: #ddd;
      margin-right: 2.5rem; }
      @media only screen and (max-width: 44em) {
        .footer-links_linkedin, .footer-links_linkedin:link, .footer-links_linkedin:visited {
          margin-right: 2rem; } }
      .footer-links_linkedin:hover, .footer-links_linkedin:link:hover, .footer-links_linkedin:visited:hover {
        cursor: pointer;
        color: #0083ca; }
    .footer-links_github, .footer-links_github:link, .footer-links_github:visited {
      color: #ddd;
      margin-right: 2.5rem; }
      @media only screen and (max-width: 44em) {
        .footer-links_github, .footer-links_github:link, .footer-links_github:visited {
          margin-right: 2rem; } }
      .footer-links_github:hover, .footer-links_github:link:hover, .footer-links_github:visited:hover {
        cursor: pointer;
        color: #2c2c2c; }
    .footer-links_email, .footer-links_email:link, .footer-links_email:visited {
      color: #ddd;
      margin-right: 2.5rem; }
      @media only screen and (max-width: 44em) {
        .footer-links_email, .footer-links_email:link, .footer-links_email:visited {
          margin-right: 2rem; } }
      .footer-links_email:hover, .footer-links_email:link:hover, .footer-links_email:visited:hover {
        cursor: pointer;
        color: #ff5e00; }
    .footer-links_resume, .footer-links_resume:link, .footer-links_resume:visited {
      color: #ddd;
      margin-bottom: 3px; }
      .footer-links_resume:hover, .footer-links_resume:link:hover, .footer-links_resume:visited:hover {
        cursor: pointer;
        color: #00c076; }
